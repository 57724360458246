.App {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.helvetica-normal {
  font-family: "Helvetica-normal";
}

.helvetica-bold {
  font-family: "Helvetica-bold";
}

.helvetica-super-bold {
  font-family: "Helvetica-super-bold";
}

.KG {
  font-family: KG;
}

.inter-normal {
  font-family: "Inter-normal";
}

.inter-bold {
  font-family: "Inter-bold";
}

.flex-column-layout {
  display: flex;
  flex-direction: column;
}

.occupy-full-height {
  flex: 1;
}

.background-color-primary {
  background-color: var(--primary-color);
  width: 100%;
  padding-bottom: 50px;
  min-height: 100vh;
}

.background-image-mum2mum {
  background-image: url("../src/assets/images/mum2MumBackground.png");
  background-size: 100% auto;
  background-position: bottom center;
  background-repeat: repeat;
}

.background-image-mum2mum-personal {
  background-image: url("../src/assets/images/mum2MumBackground.png"),
    url("../src/assets/images/mum2MumBackground2.png");
  background-size: contain;
  background-position: top center, bottom center;
  background-repeat: no-repeat;
}

.background-image-mum2mum-address {
  background-image: url("../src/assets/images/mum2MumBackground2.png");
  background-size: 100% 80%;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.mum2mum-background {
  background-image: url("../src/assets/images/newMum2mumBackground.png");
  padding: 0 !important;
  background-size: 100% auto;
}

.mum2mum-background-clear {
  background-image: url("../src/assets/images/mum2mumBackgroundImage.png");
  padding: 0 !important;
  background-size: 100% auto;
}

.mum2mum-background-top {
  height: auto;
  width: 100%;
}

.landing-page {
  min-height: 100vh;
  background-image: url("./assets/images/login-page-bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.remember-me-checkbox {
  border: 1px solid #bcbcbc;
  height: 20px;
  width: 20px;
  background-color: #d9d9d9;
}

.remember-me-checkbox[data-checked] {
  background-color: #01a7bf;
}

.remember-me-checkbox-marker {
  stroke: white;
}

.remember-me-text {
  margin-left: 10px;
  font-family: Helvetica-super-bold;
  font-size: 17px;
  font-weight: 900;
  width: 80%;
  text-align: left;
}

.otp-page {
  min-height: 100vh;
  background-image: url("./assets/images/otp-page-bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.d-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.d-column-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.image-wrapper {
  width: 100%;
  height: 10rem;
  object-fit: contain;
}
.image-background-white {
  background-color: white;
  border-radius: 10px;

}

.image-wrapper-w-40 {
  width: 40%;
  height: 10rem;
  object-fit: contain;
}

/* Text input */
.input-text-header {
  font-size: 1.3rem;
  margin: 20px 0 10px 0;
  text-align: left;
  font-weight: 700;
  display: block;
}

.input-style-login {
  border-radius: 30px;
  border: 1px solid var(--cyan-color);
  height: 50px;
  padding: 0 20px;
  width: 100%;
  background-color: transparent;
  font-family: "Helvetica-normal";
  font-size: 1rem;
}

.input-style-login:focus {
  border-color: var(--cyan-color);
  outline: none;
}

.input-style-profile {
  border-radius: 10px;
  border: 1px solid #cccccc;
  height: 50px;
  padding: 0 15px;
  width: 100%;
  background: white;
  outline: none;
  font-family: "Helvetica-normal";
  font-size: 1.125rem;
  text-align: left;
}

.input-text-style {
  border-radius: 10px;
  border: 1px solid #cccccc;
  height: 50px;
  padding: 0 15px;
  width: 100%;
  background: none;
  outline: none;
  font-family: "Helvetica-normal";
  font-size: 1.125rem;
  text-align: left;
}

.separatorLine {
  background-color: rgba(101, 101, 101, 1);
  align-self: stretch;
  display: flex;
  width: 1px;
  height: 17px;
  margin: auto 0;
}

.header {
  color: rgba(1, 167, 191, 1);
  font-size: 32px;
  font-weight: 700;
  text-align: right;
  align-self: center;
}

.description {
  color: #1b1c1a;
  text-align: center;
  font-weight: 400;
  margin-top: 16px;
}

.gray-button {
  background-color: #bcbcbc !important;
  border: none;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  color: #fff !important;
  font-size: 1.25rem;
  font-family: "Helvetica-normal";
  cursor: pointer;
}

.cyan-button {
  background-color: var(--cyan-color) !important;
  border: none;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  color: #fff !important;
  font-size: 1.25rem;
  font-family: "Helvetica-normal";
  cursor: pointer;
}

.green-button {
  background-color: var(--green-color) !important;
  border: none;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  color: #fff !important;
  font-weight: bold;
  font-size: 1.25rem;
  font-family: "Helvetica-normal";
  cursor: pointer;
}

.big-border-radius {
  border-radius: 30px;
}

.red-button {
  background-color: var(--red-color) !important;
  border: none;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  color: #fff !important;
  font-weight: bold;
  font-size: 1.25rem;
  font-family: "Helvetica-normal";
  cursor: pointer;
}

.main-page-bg {
  min-height: 100vh;
  background-image: url("./assets/images/main-page-bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  overflow: auto;
}

.image-container {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
}

.image-wrapper-small {
  height: 3rem;
  object-fit: contain;
}

/* Textarea */
.textarea-style {
  font-family: "Helvetica-normal";
  border-radius: 15px;
  border: 1px solid #cccccc;
  padding: 10px 15px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  font-size: 1.125rem;
  outline: none;
}

.no-background {
  background: none;
  outline: none;
}

/* Filter */
.filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -10px;
}

.filter-position {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.filter-dropdown {
  padding: 3px 8px;
  font-size: 13px;
  border-radius: 20px;
  border: 1px solid;
  background: transparent;
}

.border-gray-button {
  background-color: #fff;
  border: 1px solid #bcbcbc;
  height: 45px;
  width: 100%;
  border-radius: 10px;
  color: #bcbcbc;
  font-family: "Helvetica-normal";
  font-weight: bold;
  font-size: 1.25rem;
}

/* Form List */
.form-container {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px 20px;
  border-radius: 12px;
  width: 92%;
  /* margin: 0 4% 10% 4%; */
  margin: 0 4%;
}

.title-form-container {
  margin: 0 5% 5% 5%;
  position: relative;
}

.title-form {
  color: var(--cyan-color);
  font-size: 32px;
  padding: 10px 0;
}

.title-form-basic {
  font-size: 25px;
  padding: 10px 0;
}

.title-basic-left {
  font-size: 25px;
  margin-left: 30px;
  text-align: left;
}

.title-basic {
  font-size: 25px;
}

.title-description {
  font-size: 1rem;
  padding-bottom: 10px;
}

.title-subtitle {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.form-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  height: 80px;
}

/* Error message */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Dropdown */
.dropdown-menu {
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 1;
}

.dropdown-item {
  display: block;
  padding: 15px;
  width: 100%;
  text-align: left;
  border: none;
  background-color: #e2dcdc;
  font-family: "Helvetica-normal";
  font-size: 14px;
}

.dropdown-register {
  width: calc(80%);
}

.dropdown-profile {
  width: calc(92% - 40px);
}

.dropdown-height {
  height: 30vh;
}

.dropdown-scroll-bar {
  overflow-y: auto;
}

.dropdown-scroll-bar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.dropdown-scroll-bar::-webkit-scrollbar {
  width: 6px;
}

.input-disabled {
  background-color: #ccc;
  pointer-events: none;
}

.gray-input {
  background-color: #ecebeb !important;
  border: none;
}

.resend-code {
  font-size: 0.875rem;
}

.language-selector-button {
  width: 20%;
  padding: 5px;
  position: absolute;
  background-color: #ffffff;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  top: 5px;
  left: 5px;
  z-index: 2;
}

.language-selector-option {
  width: 80px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 2.5px;
  padding-left: 12px;
}

.mum2mum-footer-img {
  width: 121px;
  height: auto;
  margin: 20px;
}
