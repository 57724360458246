@font-face {
  font-family: "Helvetica-normal";
  src: url("./assets/fonts/HelveticaNeue-Condensed.ttf");
}

@font-face {
  font-family: "Helvetica-bold";
  src: url("./assets/fonts/HelveticaNeue-CondensedBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Helvetica-super-bold";
  src: url("./assets/fonts/HelveticaNeue97BlackCondensed.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Helvetica-regular";
  src: url("./assets/fonts/HelveticaNeueRoman.otf");
}

@font-face {
  font-family: "KG";
  font-weight: 900;
  src: url("./assets/fonts/KGWhYYouGoTtABeSoMeAn.ttf");
}

@font-face {
  font-family: "Inter-normal";
  src: url("./assets/fonts/Inter_18pt-Regular.ttf");
}

@font-face {
  font-family: "Inter-bold";
  src: url("./assets/fonts/Inter_18pt-SemiBold.ttf");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Helvetica-normal", "sans-serif";
  font-weight: 400;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

/* =================== */
/* ===== PRESETS ===== */
/* =================== */

:root {
  --primary-color: #faf5de;

  --cyan-color: #01a7bf;
  --green-color: #02bc7d;

  --gray-color: #bcbcbc;
  --red-color: #c4113c;
}

.cyan-color {
  color: var(--cyan-color);
}

.gray-color {
  color: var(--gray-color);
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.width-70 {
  width: 70%;
}

.push-top {
  padding-top: 80px;
}

.remove-extra {
  margin-top: -20px;
}

.push-bottom {
  padding-bottom: 140px;
}

.push-sides {
  padding-left: 10vw;
  padding-right: 10vw;
}

.f-24 {
  font-size: 24px;
}

.m-auto {
  margin: 0 auto;
}

.m-0 {
  margin: 0 !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.p-1 {
  padding: 10px;
}

.pl-1 {
  padding-left: 10px !important;
}

.pl-2 {
  padding-left: 20px !important;
}

.pl-3 {
  padding-left: 30px !important;
}

.pl-4 {
  padding-left: 40px !important;
}

.pl-5 {
  padding-left: 50px !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.pr-4 {
  padding-right: 40px !important;
}

.pr-5 {
  padding-right: 50px !important;
}

.mr-0 {
  margin-right: auto;
}

.ml-0 {
  margin-left: auto;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

.mb-10 {
  margin-bottom: 100px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-3 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-4 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-5 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mx-1 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-2 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-3 {
  margin-left: 30px;
  margin-right: 30px;
}

.mx-4 {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-5 {
  margin-left: 50px;
  margin-right: 50px;
}

.pt-1 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pt-4 {
  padding-top: 40px;
}

.pt-5 {
  padding-top: 50px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

.pb-5 {
  padding-bottom: 50px;
}

.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-4 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-5 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.px-1 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-2 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-3 {
  padding-left: 30px;
  padding-right: 30px;
}

.px-4 {
  padding-left: 40px;
  padding-right: 40px;
}

.px-5 {
  padding-left: 50px;
  padding-right: 50px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-justify {
  text-align: justify;
}

.font-weight-boldish {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-black {
  font-weight: 900;
}

.t-red {
  color: #c4113c;
}

.t-white {
  color: white;
}

.t-lgrey {
  color: #676767;
}

.t-grey {
  color: #433d3e;
}

.t-blk {
  color: black;
}

.t-orange {
  color: #fe5000;
}

.ten-t {
  font-size: 0.625rem; /* 10px */
}

.twelve-t {
  font-size: 0.75rem; /* 12px */
}

.fourteen-t {
  font-size: 0.875rem; /* 14px */
}

.sixteen-t {
  font-size: 1rem !important; /* 16px */
}

.eighteen-t {
  font-size: 1.125rem !important; /* 18px */
}

.twenty-t {
  font-size: 1.25rem !important; /* 20px */
}

.twentyfour-t {
  font-size: 1.5rem !important; /* 24px */
}

.fsize-12 {
  font-size: 12px;
}

.thirtytwo-t {
  font-size: 2rem; /* 32px */
}

.center-grid {
  display: grid;
  place-items: center;
}

.d-flex {
  display: flex !important;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.gap-10 {
  gap: 10px;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.fit-contain {
  object-fit: contain;
}

.t-underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.uppercase {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}

.font-bold {
  font-family: "Helvetica-bold";
}

.padding-bottom-100 {
  padding-bottom: 100px !important;
}

.w-20 {
  width: 20%;
}

.w-80 {
  width: 80%;
}

.bt-1-solid-grey {
  border-top: 1px solid #bbbbbb;
}
